<template>
  <div
    class="DesktopMenuContent bg-lightest border-r border-light type-sm relative select-none"
    :class="{
      animateIn: depth > 0
    }"
  >
    <!-- * * * * * * head -->
    <div v-if="depth > 0" class="type-headline-2xl pl-12 pt-20">
      {{ menuName }}
    </div>

    <div
      class="py-8 pr-8"
      :class="{
        'pl-8': depth > 0
      }"
    >
      <!-- * * * * * * primary menu -->
      <nuxt-link
        v-if="parentMenu?.mobileButton && parentMenu?.link && parentMenu.mobileButton !== ''"
        :to="$sbLinkFix(parentMenu?.link)"
        :target="$sbLinkTarget(parentMenu?.link)"
        class="btn--text m-8"
      >
        {{ parentMenu?.mobileButton }}
      </nuxt-link>
      <div>
        <div
          v-for="link in content"
          :key="link._uid"
          class="w-full h-40 pl-8
          flex items-center justify-between
          leading-single cursor-pointer hover:bg-brandLight transition-all"
          :class="{
            'text-criticalDark': checkDesign(link.design, 'sale'),
            'bg-brandLight': uiStore.isParentOfOpenMenu(link._uid),
          }"
          @click="clickMenu($sbLinkFix(link.link), (link.children.length > 0), link._uid)"
        >
          <div v-if="link.icon && link.icon.filename" class="basis-24">
            <img
              :src="link.icon.filename"
              class="w-24 h-24"
              :alt="link.title"
            >
          </div>
          <div
            class="basis-full"
            :class="{
              'pl-12': link.icon?.filename
            }"
          >
            {{ link.title }}
          </div>
          <MenuItemFavorite
            v-if="!userStore.isCustomerSuccessUser"
            :item="link"
            :hide-title="true"
          />
          <div v-if="link.children.length" class="basis-24">
            <img
              src="/icons/angle-right.svg"
              class="h-24 w-24"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { StoryBlokNestableNav } from '~/constants/types/storyblok';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import useMenuHelper from '~/composeables/useMenuHelper';
import MenuItemFavorite from '~/components/menu/MenuItemFavorite.vue';
const uiStore = useUiStore();
const userStore = useUserStore();
const globalContent = useGlobalContentStore();

const props = defineProps<{
  parent: string,
  depth: number,
}>();

let content = [] as StoryBlokNestableNav[];
let menuName = 'Menu';

let parentMenu = null as StoryBlokNestableNav | null;

const submenu = globalContent.getNavByUid(props.parent, 'main');
if (submenu) {
  parentMenu = submenu;
  content = submenu.children;
  menuName = submenu.title;
}

const router = useRouter();
const clickMenu = (url: string | null, children: boolean | null, _uid: string) => {
  if (children || _uid === 'selectCountry') {
    uiStore.addSideMenuParent(_uid, props.parent);
  } else {
    router.push({ path: `${url}` });
    uiStore.closeDesktopMenu();
  }
};

const { checkDesign } = useMenuHelper();

</script>

<style scoped lang="postcss">
.animateIn {
  animation: .0s ease-out 0s 1 SlideIn;
}
@keyframes SlideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-0%);
  }
}
</style>
